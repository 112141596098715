.clickable:hover {
  cursor: pointer;
  background: #dbe9ec;
}

.floating {
  position: absolute;
  z-index: 2;
  top: 0px;
  max-width: 50%;
}

/*Position the modals on the screen*/
.modalRight .modal-dialog {
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.modalRight .modal-header {
  height: 5vh;
}

.modalRight .modal-footer {
  height: 10vh;
}

.modalRight .modal-body {
  height: 85vh;
  overflow: auto;
}

.modalRight .modal-content {
  height: 100vh;
  border-radius: 0px;
}

.tab-content {
  background-color: #f5f5f5;
}

.sidebar .nav-item {
  --bs-accordion-bg: transparent !important;
  border: 0;
}

.marker i {
  position: relative;
}

.marker {
  position: absolute;
}


/*  ALL THE DYNAMICS */
.OptionsContainer {
  position: absolute;
  background-color: white;
  height: 100vh;
  top: 0px;
  z-index: 2;
  ;
}